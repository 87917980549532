<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top:50px"> 
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">资源通享</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">资源同享，让彼此共建和谐家园</p>
      </div>

      <div class="Photo">
        <div class="content-text">
          <p class="title">资源同享 财富通向</p>
          <p class="text">
            业主可在手机上直接发起资源服务，可1对1进行需求发布，业主也可以1对多进行需求求助，有技能资质的客户可通过手机接单，对业主进行
          </p>
        </div>
      </div>

      <div class="top" style="margin-top: 80px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">资源通运营</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">资源同享，让彼此共建和谐家园</p>
      </div>

      <div class="model">
        <div class="box">
          <p class="box-title">一、轻松创收、丰富生活</p>
          <p class="pdata">
            用户可在平台享受，资源互通，资源共享，可以浏览别人需要帮忙的订单，进行订单助力，从而达到创收，互助的双赢业态
          </p>
          <div class="content">
            <div class="l">
              <p class="t">邻里帮忙</p>
              <p class="lt">
                1对多进行视频，类型或其他需求的视频任务发布，师傅接单进行1对1接单助力，高效简洁，安全放心
              </p>
            </div>
            <div class="r">
              <el-image
                style="width: 237px; height: 447px"
                :src="require('../assets/platformp/zs.png')"
              ></el-image>
            </div>
          </div>
        </div>

        <div class="box">
          <p class="box-title">一、随时随地 找人帮忙</p>
          <p class="pdata">
            可以1对比1进行金牌互助，也可以1对多进行平台视频求助，输入你想要帮主的内容和图文，和报酬佣金，助力时间，即可随时随地发布任务
          </p>
          <div class="content">
            <div class="l">
              <p class="t">金牌助力</p>
              <p class="lt">
                1对1金牌助力，享受最专业的人士进行针对性助力，满分服务，随时下单，可以对订单进行点评，也可以进行申诉和客服或者退单等操作。
              </p>
            </div>
            <div class="r">
              <el-image
                style="width: 237px; height: 447px"
                :src="require('../assets/platformp/sz1.png')"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style scoped lang="less">
.page {
  // display: inline-block;
  // width: 1920px;
  // transform-origin: 0 0;
  // position: absolute;
  // left: 0;
  text-align: center;
  .mine {
    width: 65%;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }
  .Photo {
    background: url("../assets/platformp/bg-moge.png") no-repeat;
    background-size: 100% 100%;
    height: 500px;
    width: 1000px;
    margin: auto;
    margin-top: 80px;
    position: relative;
    text-align: left;
    .content-text {
      position: absolute;
      left: 10%;
      top: 10%;
      .title {
        font-size: 34px;
        font-weight: 400;
        color: #333333;
      }
      .text {
        width: 380px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
      }
    }
  }

  .model {
    text-align: left;
    margin-top: 180px;
    .box {
      .box-title {
        font-weight: bold;
        color: #333333;
        font-size: 24px;
      }
      .pdata {
        width: 548px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
      }
      .content {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
        height: 350px;
        background: linear-gradient(90deg, #a7bae1 0%, #ffffff 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 30px 0 30px 100px;
        p {
          margin: 0;
        }
        .l {
          .t {
            font-size: 33px;
            font-weight: 400;
          }
          .lt {
            margin-top: 20px;
            font-size: 20px;
            font-weight: bold;
            width: 500px;
          }
        }
      }
      .reserve {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        background: linear-gradient(-90deg, #a7bae1 0%, #ffffff 100%);
        padding: 30px 100px 30px 0px;
      }
    }
  }
}
</style>
